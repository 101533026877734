module.exports = ({ fetch }) => {
  return {
    getShoppingCart: ({ shopId, token, sessionId, entityId, language }) =>
      fetch(`/mygon-middleware/rest/v1/web/shopping-cart`, {
        method: "get",
        queryParams: { shopId, token, sessionId, entityId, language },
      }),
    applyCouponCode: ({ shopId, token, sessionId, entityId, couponCode, language }) =>
      fetch(`/mygon-middleware/rest/v1/web/shopping-cart/apply-coupon`, {
        method: "post",
        queryParams: { shopId, token, sessionId, entityId, couponCode, language },
      }),
    removeCartItem: ({ itemId, token, sessionId, entityId, language }) =>
      fetch(`/mygon-middleware/rest/v1/web/shopping-cart/remove/${itemId}`, {
        method: "post",
        queryParams: { token, sessionId, entityId, language },
      }),
  };
};
