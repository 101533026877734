import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import localeEnUsCommon from "public/static/locales/en-US/common.json";
import localePtPtCommon from "public/static/locales/pt-PT/common.json";
import localeEsEsCommon from "public/static/locales/es-ES/common.json";

export const availableLocales = [
  {
    code: 'pt_PT',
    image: 'https://www.mygon.com/reservation-widget/static/images/flags/pt_PT.svg',
    language: 'Português',
  },
  {
    code: 'es_ES',
    image: 'https://www.mygon.com/reservation-widget/static/images/flags/es_ES.svg',
    language: 'Español',
  },
  {
    code: 'en_EN',
    image: 'https://www.mygon.com/reservation-widget/static/images/flags/en_EN.svg',
    language: 'English',
  },
]

const mygonLangCodeMapper = {
  pt_PT: "pt-PT",
  es_ES: "es-ES",
  // Mygon website actually uses en_EN (which is not a valid locale)
  en_EN: "en-US",
  en_US: "en-US"
};

const resources = {
  "en-US": {
    common: localeEnUsCommon
  },
  "es-ES": {
    common: localeEsEsCommon
  },
  "pt-PT": {
    common: localePtPtCommon
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: "common",
    defaultNS: "common",
    lng: "pt-PT",
    fallbackLng: "pt-PT",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export const changeLanguage = mygonLangCode => {
  i18n.changeLanguage(mygonLangCodeMapper[mygonLangCode] || "pt-PT");
};

export const getLanguageCode = () => {
  return i18n.language.substr(0, 2);
};

export const getLocalizedWinPrizesUri = () => {
  const uris = {
    pt: "/pt/ganhe-premios",
    en: "/en/win-prizes",
    es: "/es/gana-premios"
  };

  const code = getLanguageCode();
  return uris[code] || uris["pt"];
};

export const getLocalizedPrivacyPolicyUri = () => {
  const uris = {
    pt: "/pt/politicas-de-privacidade",
    en: "/en/privacy-policy",
    es: "/es/politicas-de-privacidad"
  };

  const code = getLanguageCode();
  return uris[code] || uris["pt"];
};

export const getLocalizedTermsAndConditionsUri = () => {
  const uris = {
    pt: "/pt/utilizadores-termos-e-condicoes",
    en: "/en/users-terms-and-conditions",
    es: "/es/utilizadores-terminos-y-condiciones"
  };

  const code = getLanguageCode();
  return uris[code] || uris["pt"];
};

export default i18n;
