import {getRwgShopId, getRwgToken} from "../../../helpers/rwgToken"

const APPOINTMENT_RWG_CONVERSION = "APPOINTMENT_RWG_CONVERSION";

const rwgConversion = () => (dispatch, getState) => {
  const state = getState()
  const endpoint = process.env.MG_RWG_CONVERSION_ENDPOINT
  const partnerId = process.env.MG_RWG_CONVERSION_PARTNER_ID

  const rwgToken = getRwgToken()
  const shopId = state.shop.id

  if (!rwgToken || rwgToken === '') {
    return Promise.resolve()
  }

  const savedShop = getRwgShopId()
  const merchantChanged = savedShop && parseInt(savedShop) !== shopId ? 1 : 2

  return fetch(endpoint, {
    method: "POST",
    body: JSON.stringify({
      conversion_partner_id: partnerId,
      rwg_token: rwgToken,
      merchant_changed: merchantChanged
    })
  })
}

rwgConversion.action = APPOINTMENT_RWG_CONVERSION;

export default rwgConversion;
