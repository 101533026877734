import FlowStepAuthentication from "components/flow-step-authentication";
import FlowStepBookingVoucherConfirmation from "components/flow-step-booking-voucher-confirmation";
import FlowStepBookingVoucherDetails from "components/flow-step-booking-voucher-details";
import FlowStepConfirmPurchase from "components/flow-step-confirm-purchase";
import FlowStepMenu from "components/flow-step-menu";
import FlowStepMenuCart from "components/flow-step-menu-cart";
import FlowStepReservationType from "components/flow-step-reservation-type";
import FlowStepServiceListing from "components/flow-step-service-listing";
import FlowStepShoppingCart from "components/flow-step-shopping-cart";
import FlowStepCheckoutConfirmation from 'components/flow-step-product-checkout/checkout-confirmation'
import FlowStepDeliveryMethod from "components/flow-step-product-checkout/delivery-method-selection"
import FlowStepPaymentMethod from "components/flow-step-product-checkout/payment-method-selection"
import {trackFbEvent, triggerEvent} from "lib/analytics";
import Cookies from "js-cookie";
import api from "lib/api";
import {AllReservationModes, AllReservationTypes, ReservationModes,} from "lib/helpers/constants";
import {changeLanguage} from "lib/helpers/i18n";
import actions from "lib/redux/actions";
import selectors from "lib/redux/selectors";
import {useRouter} from "next/router";
import qs from "qs";
import React, {useCallback, useEffect, useMemo, useRef, useState,} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import VoucherPaymentGuestInfo from "components/flow-step-confirm-purchase/voucher-payment-guest-info";
import {getAndUpdateRwgToken} from "../helpers/rwgToken"

const isServer = typeof window === "undefined";

export const FlowTypes = {
  RESERVATION_TYPE_BOOKING_VOUCHER: "reservation_type_booking_voucher",
  BOOKING_VOUCHER: "booking_voucher",
  TRANSPORT_ORDER: "transport_order",
  MENU_AND_TRANSPORT_ORDER: "menu_and_transport_order",
  MENU_ONLY: "menu_only",
  PRODUCT_CHECKOUT: 'product-checkout',
  SERVICE_LISTING_AND_RESERVATION: "service_listing_and_reservation",
  SERVICE_MENU_AND_TRANSPORT_ORDER: "service_menu_and_transport_order",
  SERVICE_AND_RESERVATION: "service_and_reservation",
  CAMPAIGN_TYPE_5_SKIP: "skip_campaign_type_5_first_step"
};

const menuAndTransportOrderSteps = [
  {
    component: FlowStepMenuCart,
    flowState: {
      isTransported: true,
    },
  },
  { component: FlowStepShoppingCart },
  { component: FlowStepDeliveryMethod },
  { component: FlowStepBookingVoucherDetails },
  { component: FlowStepAuthentication, skipWhenLoggedIn: true, skipIfGuestLoginAllowed: true },
  { component: FlowStepConfirmPurchase },
  { component: FlowStepBookingVoucherConfirmation },
];

const componentsByFlowAndStep = {
  [FlowTypes.CAMPAIGN_TYPE_5_SKIP]: {
    steps: [
      { component: FlowStepAuthentication, skipWhenLoggedIn: true, skipIfGuestLoginAllowed: true },
      { component: FlowStepConfirmPurchase },
      { component: FlowStepBookingVoucherConfirmation },
    ],
  },
  [FlowTypes.RESERVATION_TYPE_BOOKING_VOUCHER]: {
    steps: [
      { component: FlowStepReservationType },
      { component: FlowStepBookingVoucherDetails },
      { component: FlowStepAuthentication, skipWhenLoggedIn: true, skipIfGuestLoginAllowed: true },
      { component: VoucherPaymentGuestInfo, skipWhenLoggedIn: true },
      { component: FlowStepConfirmPurchase },
      { component: FlowStepBookingVoucherConfirmation },
    ],
  },
  [FlowTypes.BOOKING_VOUCHER]: {
    steps: [
      { component: FlowStepBookingVoucherDetails },
      { component: FlowStepAuthentication, skipWhenLoggedIn: true, skipIfGuestLoginAllowed: true },
      { component: VoucherPaymentGuestInfo, skipWhenLoggedIn: true },
      { component: FlowStepConfirmPurchase },
      { component: FlowStepBookingVoucherConfirmation },
    ],
  },
  [FlowTypes.TRANSPORT_ORDER]: {
    steps: [
      { component: FlowStepShoppingCart },
      { component: FlowStepDeliveryMethod },
      { component: FlowStepBookingVoucherDetails },
      { component: FlowStepAuthentication, skipWhenLoggedIn: true, skipIfGuestLoginAllowed: true },
      { component: FlowStepConfirmPurchase },
      { component: FlowStepBookingVoucherConfirmation },
    ],
  },
  [FlowTypes.SERVICE_AND_RESERVATION]: {
    headerProps: {
      showProductName: true,
    },
    init: (state) => {
      const prodInfo = selectors.product.info(state);
      const isTakeawayAndDelivery = prodInfo.bookingType === 7;

      return isTakeawayAndDelivery
        ? FlowTypes.SERVICE_MENU_AND_TRANSPORT_ORDER
        : FlowTypes.SERVICE_AND_RESERVATION
    },
    steps: [
      { component: FlowStepBookingVoucherDetails },
      { component: FlowStepAuthentication, skipWhenLoggedIn: true, skipIfGuestLoginAllowed: true },
      { component: FlowStepConfirmPurchase },
      { component: FlowStepBookingVoucherConfirmation },
    ],
  },
  [FlowTypes.SERVICE_LISTING_AND_RESERVATION]: {
    steps: [
      {
        component: FlowStepServiceListing,
        flowState: {
          isTransported: false,
          [ReservationModes.BOOK_DATE]: {
            selectedDayIsAvailable: false,
            date: null,
            dateTimes: [],
          },
        },
      },
      {
        subFlow: (state) => {
          const prodInfo = selectors.product.info(state);
          const isTakeawayAndDelivery = prodInfo.bookingType === 7;
          return isTakeawayAndDelivery
            ? FlowTypes.SERVICE_MENU_AND_TRANSPORT_ORDER
            : FlowTypes.SERVICE_AND_RESERVATION;
        },
      },
    ],
  },
  [FlowTypes.SERVICE_MENU_AND_TRANSPORT_ORDER]: {
    steps: menuAndTransportOrderSteps,
  },
  [FlowTypes.MENU_AND_TRANSPORT_ORDER]: {
    headerProps: {
      showShopName: true,
    },
    steps: menuAndTransportOrderSteps,
  },
  [FlowTypes.MENU_ONLY]: {
    headerProps: {
      isMenu: true,
      showShopName: true,
    },
    steps: [{ component: FlowStepMenu }],
  },
  [FlowTypes.PRODUCT_CHECKOUT]: {
    headerProps: {
        showShopName: true,
    },
    steps: [
      { component: FlowStepShoppingCart },
      { component: FlowStepAuthentication, skipWhenLoggedIn: true, skipIfGuestLoginAllowed: true },
      { component: FlowStepDeliveryMethod },
      { component: FlowStepPaymentMethod },
      { component: FlowStepCheckoutConfirmation },
    ],
  },
};

const normalizeParams = (original) => {
  const shop = original.shopId
      ? parseInt(original.shopId, 10)
      : (original.shop ? parseInt(original.shop, 10) : null)

  if (original.entityID === "4") {
    // MyEdenred
    return {
      ...original,
      externalToken: original.token,
      campaignId: original.campaignId,
      language: original.lang,
      shopId: shop,
      providerId: parseInt(original.entityID, 10), //@deprecated
      clientApplication: original.channel
        ? original.channel.toUpperCase()
        : null,
      canBuyVoucher: false,
      channelManager: original.channelManager ? parseInt(original.channelManager, 10) : null,
    };
  }

  let entityId = original.entityId
    ? parseInt(original.entityId)
    : parseInt(original.providerId)

  if (!entityId || isNaN(entityId)) {
    entityId = 1
  }

  const hasServices = typeof original.services !== "undefined"
  const allowGuestLogin = typeof original.gL !== 'undefined'

  return {
    ...original,
    providerId: entityId, //@deprecated
    shoppingCart: getShoppingCart(original),
    campaignId: original.id,
    shopId: shop,
    serviceId: original.serviceId ? parseInt(original.serviceId, 10) : null,
    canBuyVoucher: !hasServices,
    allowGuestLogin,
  };
};

const getShoppingCart = (params) => {
  if (params.shoppingCartItems) {
    return {
      items: JSON.parse(params.shoppingCartItems)
        .map(item => ({
          id: parseInt(item.id),
          productId: parseInt(item.productId),
          quantity: parseInt(item.qty),
        }))
    }
  }

  if (params.shoppingCart) {
    return {
      items: params.shoppingCart
        .split(",")
        .filter(Boolean)
        .map((id, idx) => ({
          id: parseInt(id),
          idx: idx,
          quantity: 1,
        }))
    }
  }

  return {
    items: [],
  }
}

export const useFlow = ({
  clientOnly,
  queryOverrides = {},
  flowType: defaultFlowType = FlowTypes.BOOKING_VOUCHER,
} = {}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [ready, setReady] = useState(false)
  const [flowLevel, setFlowLevel] = useState(0)
  const [initFlowType, setInitFlowType] = useState(null)
  const [flowState, setFlowState] = useState()
  const [campaignError, setCampaignError] = useState(null)
  const [headerProps, setHeaderProps] = useState({})
  const [formikProps, setFormikProps] = useState({})

  //TODO - rewrite this - it makes no sense. Or better - rewrite this whole use-flow file!
  // this causes an error in next@11 and react@17
  // and trying to fix it by using redux just causes an infinite loop
  // app flow shouldn't be this complex, nor should it handle every single flow case here...
  const handleFormikProps = useCallback((props) => {
    setFormikProps(props);
  }, []);

  const dispatch = useDispatch();
  const stateRef = useRef();
  const state = useSelector((state) => state);
  const stepState = useSelector((state) => state.flow.steps);
  const context = useSelector((state) => state.context);
  const campaignIsAvailable = useSelector(selectors.campaign.isAvailable);
  const canBookDate = useSelector(selectors.campaign.canBookDate);
  const canBuyVoucher = useSelector(selectors.campaign.canBuyVoucher);
  const loggedIn = useSelector(selectors.user.loggedIn);
  stateRef.current = state;

  useEffect(
    function updateCampaignStatus() {
      if (flowState) {
        const flowType = flowState[flowLevel].type;
        if (
          flowType === FlowTypes.BOOKING_VOUCHER &&
          ready &&
          !campaignError &&
          !campaignIsAvailable
        ) {
          setCampaignError(t("campaignErrors.unavailable"));
        }
      }
    },
    [flowState, flowLevel, campaignError, ready, campaignIsAvailable]
  );

  useEffect(
    function updateLanguage() {
      changeLanguage(context.language);

      if (ready) {
        loadRequiredData()
      }
    },
    [context.language]
  );

  useEffect(
    // this doesn't really do anything beause each API module seems to be creating their own instance of fetch
    function updateFetchBaseQueryParams() {
      api.fetch.updateQueryParams({
        clientApplication: context.clientApplication,
        language: context.language,
      });
    },
    [context.language, context.clientApplication]
  );

  useEffect(
    function initialize() {
      if (clientOnly && isServer) {
        return;
      }

      const containsQueryParams = window.location.search;
      const queryParamsCount = Object.keys(router.query).length;

      if (containsQueryParams && !queryParamsCount) {
        // We pass here twice on client-only apps
        // https://github.com/zeit/next.js/issues/8259
        return;
      }

      const fragmentParams = qs.parse(window.location.hash.slice(1));
      const { query } = router;
      const params = normalizeParams({
        ...query,
        ...fragmentParams,
        ...queryOverrides,
      });

      const hasCampaign = !!params.campaignId;
      const hasReservationType = AllReservationTypes.includes(params.reservationType);
      const hasReservationMode = AllReservationModes.includes(params.reservationMode);
      const hasShoppingCart = typeof params.shoppingCart !== "undefined" && params.shoppingCart.items.length > 0
      const hasMenu = typeof params.menu !== "undefined";
      const hasServices = typeof params.services !== "undefined";
      const isProductCheckout = typeof params.products !== 'undefined'
      const hasServiceId = !!params.serviceId;
      const hasShopId = !!params.shopId;
      const hasReferralCode = typeof params.referralCode !== "undefined";

      let flowTypeArg;
      if (isProductCheckout) {
        flowTypeArg = FlowTypes.PRODUCT_CHECKOUT
      } else if (hasMenu && (hasCampaign || hasShoppingCart)) {
        flowTypeArg = FlowTypes.MENU_AND_TRANSPORT_ORDER;
      } else if (hasMenu) {
        flowTypeArg = FlowTypes.MENU_ONLY;
      } else if (hasServices && hasServiceId) {
        flowTypeArg = FlowTypes.SERVICE_AND_RESERVATION;
      } else if (hasServices) {
        flowTypeArg = FlowTypes.SERVICE_LISTING_AND_RESERVATION;
      } else if (hasShoppingCart) {
        flowTypeArg = FlowTypes.TRANSPORT_ORDER;
      } else if (hasReservationType || params.providerId === 4) {
        flowTypeArg = FlowTypes.BOOKING_VOUCHER;
      } else {
        flowTypeArg = FlowTypes.RESERVATION_TYPE_BOOKING_VOUCHER;
      }
      setInitFlowType(flowTypeArg)

      // Set the context
      const showClose = params.showClose && params.showClose !== "false";
      dispatch(
        actions.context.setContext({
          ...params,
          showClose,
        })
      );

      getAndUpdateRwgToken(params.rwg_token, params.shopId)

      dispatch(actions.entity.fetchEntityConfiguration()).then(() => {
        const fetchPromises = [];

        const envPrefix = process.env.ENV === 'production' ? '' : process.env.ENV
        const sessionUserId = params.userId || Cookies.get(`${envPrefix}mygon_user_id`);
        const sessionUserToken = params.token || Cookies.get(`${envPrefix}mygon_user_token`);
        if (params.externalToken && params.providerId === 4) {
          // Logged-in user from a 3rd-party provider such as MyEdenred
          // Fetch the corresponding Mygon user, if any
          fetchPromises.push(
            dispatch(
              actions.user.fetchUserWithExternalAuth({
                externalToken: params.externalToken,
                providerId: params.providerId,
              })
            ).catch(() => {
              // Ignore error, we simply allow the user to go on without auth
            })
          );
        } else if (sessionUserId && sessionUserToken) {
          // Logged-in user, fetch the user profile info from the server
          fetchPromises.push(
            dispatch(
              actions.user.fetchUser({
                userId: sessionUserId,
                token: sessionUserToken,
              })
            )
          );
        }

        const stepStateData = {
          flowTypeArg: flowTypeArg,
          ...(hasServiceId && { serviceId: params.serviceId }),
          ...(hasCampaign && { campaignId: params.campaignId }),
          ...(hasShopId && { shopId: params.shopId }),
          ...(hasReservationType && {
            reservationType: params.reservationType,
            defaultView: params.defaultView,
          }),
          ...(hasReservationMode && {
            mode: params.reservationMode,
          }),
          ...(params.allowGuestLogin && { allowGuestLogin: params.allowGuestLogin }),
        };

        if (hasReferralCode) {
          stepStateData.referralCode = params.referralCode
        }

        if (isProductCheckout) {
          stepStateData.showMenu = hasMenu
          stepStateData.isTransported = true

          fetchPromises.push(
            dispatch(actions.shoppingCart.fetchShoppingCart({
              token: sessionUserToken,
              sessionId: params.sessionId,
            }))
          )
        } else if (flowTypeArg === FlowTypes.MENU_AND_TRANSPORT_ORDER) {
          stepStateData.isTransported = true
        }

        dispatch(
          actions.flow.setStepState({
            data: stepStateData,
          })
        )

        loadRequiredData(stepStateData, fetchPromises)
      })
    },
    [router.query]
  );

  const loadRequiredData = useCallback((data = null, fetchPromises = []) => {
    const state = data || stepState
    const flowTypeArg = stepState.flowTypeArg

    if (!!state.serviceId || !!state.shopId) {
      fetchPromises.push(dispatch(actions.categories.fetchCategories()))
    }

    if (
      [
        FlowTypes.RESERVATION_TYPE_BOOKING_VOUCHER,
        FlowTypes.BOOKING_VOUCHER,
        FlowTypes.TRANSPORT_ORDER,
      ].includes(flowTypeArg) &&
      !state.campaignId
    ) {
      setCampaignError(t("campaignErrors.notProvided"));
    } else if (flowTypeArg === FlowTypes.MENU && !state.shopId) {
      // TODO different error
      setCampaignError(t("campaignErrors.notProvided"));
    } else {
      if (state.campaignId) {
        setCampaignError(null);

        const campaignId = state.campaignId;
        fetchPromises.push(
          dispatch(actions.campaign.fetchCampaign({ campaignId }))
        );
        fetchPromises.push(
          dispatch(actions.campaign.fetchSchedule({ campaignId }))
        );
        fetchPromises.push(
          dispatch(actions.campaign.fetchCalendarSlots({ campaignId }))
        )
      }
      if (state.shopId) {
        fetchPromises.push(
          dispatch(actions.shop.fetchShop({ shopId: state.shopId }))
        );
      }
      if (state.serviceId) {
        fetchPromises.push(
          dispatch(
            actions.shop.fetchShopCalendarSlots({
              shopMerchantServiceId: state.serviceId,
            })
          )
        );
      }

      Promise.all(fetchPromises)
        .then(() => {
          if (!ready) {
            setReady(true)
          }
        })
        .catch((err) => {
          if (err.statusCode === 404) {
            // TODO different error
            setCampaignError(t("campaignErrors.invalid"));
          } else if (err.statusCode === 401) {
            // remove session cookies
            const envPrefix = process.env.ENV === 'production' ? '' : process.env.ENV
            Cookies.remove(`${envPrefix}mygon_user_id`)
            Cookies.remove(`${envPrefix}mygon_user_token`)

            // retry loading data without session
            loadRequiredData()
          } else {
            setCampaignError(err.message);
          }
        });
    }
  }, [stepState, initFlowType])

  const updateStepState = useCallback(
    (data) => dispatch(actions.flow.setStepState({ data })),
    []
  );

  useEffect(
    function firstRun() {
      // todo: why does this run on start when it should only run on deps change???
      if (ready && initFlowType) {
        const stepFlow = componentsByFlowAndStep[initFlowType]

        let flowType = initFlowType
        if (stepFlow.init) {
          flowType = stepFlow.init(stateRef.current)
        }

        let flowState = [
          {
            type: flowType,
            step: 0,
          },
        ]

        if (ready && flowType !== FlowTypes.MENU_ONLY) {
          let eventLabel;
          if (canBookDate && canBuyVoucher) {
            eventLabel = "Booking+Gift";
          } else if (canBookDate) {
            eventLabel = "Booking";
          } else if (canBuyVoucher) {
            eventLabel = "Gift";
          }
          triggerEvent("Purchase", "open_booking_form", eventLabel);
          trackFbEvent("AddToCart");
        }

        setFlowState(flowState)
      }
    },
    [ready]
  );

  const nextStep = useCallback(() => {
    const { type, step } = flowState[flowLevel];
    const nextStepObj = componentsByFlowAndStep[type].steps[step + 1];
    if (nextStepObj.subFlow) {
      const subFlow = nextStepObj.subFlow(stateRef.current);
      const nextFlowLevel = flowLevel + 1;
      setFlowState((curState) => {
        return [
          ...curState,
          {
            type: subFlow,
            step: 0,
          },
        ];
      });
      setFlowLevel(nextFlowLevel);
    } else {
      let stepIncrement = (loggedIn && nextStepObj.skipWhenLoggedIn)
      || (nextStepObj.skipIfGuestLoginAllowed && context.allowGuestLogin)
        ? 2 : 1;

      // temp workaround - check next steps and validate if they need to be skipped as well
      // this makes no sense btw - a proper navigation system shouldn't need to check steps like this
      // a proper navigation system would have a very basic navigation and it's pages linkeed together through a router
      // not whatever this custom code is

      let nextStep = componentsByFlowAndStep[type].steps[step + stepIncrement]
      if ((loggedIn && nextStep.skipWhenLoggedIn)
      || (nextStep.skipIfGuestLoginAllowed && context.allowGuestLogin)) {
        stepIncrement += 1
      }

      setFlowState((curState) => [
        ...curState.slice(0, flowLevel),
        {
          ...curState[flowLevel],
          step: curState[flowLevel].step + stepIncrement,
        },
        ...curState.slice(flowLevel + 1),
      ]);
    }
  }, [stateRef, flowState, flowLevel, loggedIn, context.allowGuestLogin]);

  const prevStep = useCallback(() => {
    if (step === 0) {
      setFlowState((curState) => [...curState.slice(0, flowLevel)]);
      setFlowLevel(flowLevel - 1);
    } else {
      const { type, step } = flowState[flowLevel];
      const prevStepObj = componentsByFlowAndStep[type].steps[step - 1];
      let stepDecrement = (loggedIn && prevStepObj.skipWhenLoggedIn)
      || (prevStepObj.skipIfGuestLoginAllowed && context.allowGuestLogin)
        ? 2
        : 1;

      // temp workaround - check next steps and validate if they need to be skipped as well
      // this makes no sense btw - a proper navigation system shouldn't need to check steps like this
      // a proper navigation system would have a very basic navigation and it's pages linkeed together through a router
      // not whatever this custom code is
      let previousStep = componentsByFlowAndStep[type].steps[step - stepDecrement]
      if ((loggedIn && previousStep.skipWhenLoggedIn)
        || (previousStep.skipIfGuestLoginAllowed && context.allowGuestLogin)) {
        stepDecrement += 1
      }

      setFlowState((curState) => [
        ...curState.slice(0, flowLevel),
        {
          ...curState[flowLevel],
          step: curState[flowLevel].step - stepDecrement,
        },
      ]);
    }
  }, [flowState, flowLevel, loggedIn, context.allowGuestLogin]);

  const { type: flowType, step } = flowState ? flowState[flowLevel] : {};

  useEffect(
    function updateFlowStepState() {
      if (flowState) {
        const { type: flowType, step } = flowState[flowLevel]
        const stepConfig = componentsByFlowAndStep[flowType].steps[step];
        if (stepConfig.flowState) {
          updateStepState(stepConfig.flowState);
        }
      }
    },
    [initFlowType, flowState, flowLevel, step]
  );

  useEffect(
    function updateHeaderProps() {
      if (flowState) {
        const {type: flowType, step} = flowState[flowLevel]
        const headerProps = componentsByFlowAndStep[flowType].headerProps || {};
        setHeaderProps(headerProps)
      }
    },
    [initFlowType, flowState, flowLevel]
  );

  // For some reason, each time `onFormikProps()` was called, the subsequent
  // setState update made us re-render the current step component even though
  // none of the passed properties were changed. Memoizing the component itself
  // solves this issue.
  const stepComponent = useMemo(
    () => {
      if (ready && flowState) {
        const { type: flowType, step } = flowState[flowLevel]
        const Component = componentsByFlowAndStep[flowType].steps[step].component

        return Component ? (
          <Component
            stepState={stepState}
            updateStepState={updateStepState}
            onFormikProps={handleFormikProps}
            onNextStep={nextStep}
            onPrevStep={prevStep}
          />
        ) : null
      }

      return null
    },
    [
      ready,
      stepState,
      updateStepState,
      handleFormikProps,
      nextStep,
      prevStep,
      flowLevel,
      flowState,
    ]
  );

  const onClose = null;
  const onPrevStep =
    formikProps.onBack || (step >= 1 || flowLevel > 0 ? prevStep : null);


  const onNextStep = useMemo(() => {
    if (flowState) {
      const { type: flowType, step } = flowState[flowLevel]

      return componentsByFlowAndStep[flowType].steps[step + 1]
        ? nextStep
        : null;
    }

    return null
  }, [
    ready,
    stepState,
    nextStep,
    prevStep,
    flowLevel,
    flowState,
  ])

  return {
    ready,
    campaignError,
    step,
    stepComponent,
    formikProps,
    onClose,
    onPrevStep,
    onNextStep,
    flowType,
    headerProps,
  };
};
