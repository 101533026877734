import {Button, Form} from "react-bootstrap"
import React, {useCallback, useState} from "react"
import {css} from "@emotion/core"
import {useDispatch, useSelector} from "react-redux"
import {actions, selectors} from "lib/redux"
import {useTranslation} from "react-i18next"
import cssVars from "styles/variables.module.scss"

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.4rem',
  }),
  field: css({
    flexGrow: 1,
    border: `1px solid ${cssVars.gray500}`,
    borderRadius: "0.5rem",
    "&.success": {
      borderColor: cssVars.green,
    },
  }),
  button: css({
    width: 'fit-content',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    minWidth: '6rem',
    backgroundColor: 'white',
    border: `1px solid ${cssVars.green}`,
    color: cssVars.green,
    fontSize: '1rem',
    "&.success": {
      fontWeight: 500,
      borderColor: 'white',
    },
  })
}

export default function ApplyCouponCode() {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [couponCode, setCouponCode] = useState("")
  const [invalid, setInvalid] = useState(false)
  const appliedCouponCode = useSelector(selectors.shoppingCart.appliedCouponCode)

  const applyCouponCode = useCallback(() => {
    if (!appliedCouponCode) {
      try {
        dispatch(actions.shoppingCart.applyCouponCode(couponCode)).catch(e => {
          console.log('error applying coupon code', e.message)
          console.error(e)
          setInvalid(true)
        })
        setInvalid(false)
      } catch(e) {
        console.log('error applying coupon code', e.message)
        console.error(e)
        setInvalid(true)
      }
    }
  }, [couponCode])

  return (
    <div className="mb-4">
      <Form.Group className="mb-0">
        <Form.Label className="medium-text">
          {t("couponCode.label")}
        </Form.Label>
        <div css={styles.container}>
          <Form.Control
            css={styles.field}
            type="text"
            name="name"
            value={appliedCouponCode ? appliedCouponCode : couponCode}
            disabled={appliedCouponCode}
            onChange={e => setCouponCode(e.target.value)}
            isInvalid={invalid}
            className={appliedCouponCode ? "success" : ""}
          />
          <Button
            css={styles.button}
            className={appliedCouponCode ? "success" : ""}
            aria-label={t('couponCode.applyAriaLabel')}
            onClick={applyCouponCode}
          >
            {appliedCouponCode ? t('couponCode.applied') : t('couponCode.apply')}
          </Button>
        </div>
      </Form.Group>
      {invalid && (
        <span>{ t('couponCode.invalidCoupon') }</span>
      )}
    </div>
  )
}
